body.hiden {
  overflow: hidden;
}
#root {
  height: 100%;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
html,
body {
  height: 100%;
  width: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 120%;
  font-weight: 500;
  background: $white;
  color: $black;
}

h1 {
  font-size: 52px;
  line-height: 125%;
}
h2 {
  font-size: 32px;
  line-height: 125%;
}
.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.main {
  flex: 1 1 auto;
}
.container {
  max-width: 1345px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.header__menu-btn {
  display: none;
  margin-left: auto;
  width: 20px;
  height: 16px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.header__menu-btn span {
  display: block;
  position: absolute;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 2px;
  width: 100%;
  background: $black;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.header__menu-btn span:nth-child(1) {
  top: 0px;
}

.header__menu-btn span:nth-child(2),
.header__menu-btn span:nth-child(3) {
  top: 7px;
  width: 100%;
}

.header__menu-btn span:nth-child(4) {
  top: 14px;
  width: 100%;
  right: 0;
  left: inherit;
}
.header__menu-btn.open span {
  border-radius: 10px;
}
.header__menu-btn.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 100%;
  display: none;
}

.header__menu-btn.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 100%;
}

.header__menu-btn.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 100%;
}

.header__menu-btn.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 100%;
  display: none;
}

.subscribe {
  display: flex;
  align-items: center;
  height: 25px;
  gap: 10px;
  input {
    height: 100%;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #9f9f9f;
    border-bottom: 1px solid #000;
  }
  input::placeholder {
    color: #616161;
  }
  button {
    max-width: 78px;
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 500;
    transition: all 0.3s ease;
    border-bottom: 1px solid #000;
    text-transform: uppercase;
    color: $black;
  }
  button:hover {
    color: $white;
    background-color: $black;
  }
}
.btn {
  border-radius: 15px;
  background: $white;
  font-weight: 400;
  font-size: 20px;
  color: #000;
  padding: 10px;
  min-height: 67px;
  transition: all 0.3s ease;
  border: 1px solid #000;
  min-width: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn:hover {
  background: $black;
  color: $white;
}

.form-input-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.done {
    label {
      color: $black;
    }
  }
  label {
    color: $black;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
  input {
    width: 100%;
    padding: 0px 5px;
    height: 35px;
    border-bottom: 1px solid $black;
    color: $black;
  }
}
.form-input-item:hover {
  input {
    border-bottom: 1px solid $black;
  }
  label {
    color: $black;
  }
}

.form-input-textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.done {
    label {
      color: $black;
    }
  }
  label {
    color: $black;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
  }
  textarea {
    width: 100%;
    height: 35px;
    border-bottom: 1px solid $black;
    color: $black;
    outline: none;
    resize: none;
  }
}

.form-input-textarea:hover {
  textarea {
    border-bottom: 1px solid $black;
  }
  label {
    color: $black;
  }
}

.checkbox-item {
  padding-left: 27px;
  display: flex;
  align-items: center;
  .checkbox-radio {
    display: none;
  }
  label {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 13px;
      height: 13px;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1_771)'%3E%3Cpath d='M5.80556 10.5388L6.20153 10.8748L6.52229 10.4664L10.2646 5.70152L10.5679 5.31529L10.1872 5.00507L9.51117 4.45426L9.11661 4.13277L8.80217 4.53297L5.9319 8.18606L4.06752 6.60389L3.67938 6.2745L3.35702 6.66849L2.80235 7.34642L2.49136 7.72652L2.86583 8.04428L5.80556 10.5388ZM0.5 7.32408C0.5 5.69792 1.13433 4.14018 2.26059 2.99306C3.38654 1.84626 4.91169 1.2037 6.5 1.2037C8.08831 1.2037 9.61346 1.84626 10.7394 2.99306C11.8657 4.14018 12.5 5.69792 12.5 7.32408C12.5 8.95023 11.8657 10.508 10.7394 11.6551C9.61346 12.8019 8.08831 13.4444 6.5 13.4444C4.91169 13.4444 3.38654 12.8019 2.26059 11.6551C1.13433 10.508 0.5 8.95023 0.5 7.32408Z' fill='black' stroke='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1_771'%3E%3Crect width='13' height='13.2407' fill='white' transform='translate(0 0.703705)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      border-radius: 50%;
      left: -27px;
      top: 3px;
      transform: scale(0) rotateZ(180deg);
      z-index: 1;
    }
    &::after {
      content: '';
      position: absolute;
      background-color: #e0e0e0;
      border-radius: 50%;
      width: 13px;
      height: 13px;
      left: -27px;
      top: 3px;
    }
  }
}

.checkbox-radio:checked + label::before {
  transform: scale(1) rotateZ(0deg);
}

.breadcrumbs {
  background-color: #cecece;
}
.breadcrumbs-list {
  min-height: 102px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  a + a {
    margin-left: 60px;
  }
  a {
    font-weight: 400;
    color: #9f9f9f;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: -31px;
      top: 50%;
      transform: translateY(-50%);
      width: 9px;
      height: 15px;
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 13.1749L5.25 7.9249L0 2.6749L1.05 0.574902L8.4 7.9249L1.05 15.2749L0 13.1749Z' fill='black'/%3E%3C/svg%3E%0A");
    }
  }
  span {
    margin-left: 70px;
    padding-left: 35px;
    font-weight: 400;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 38px;
      background-color: #9f9f9f;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

label {
  font-weight: 500;
}
.input {
  width: 100%;
  height: 75px;
  border: 1px solid #9f9f9f;
  border-radius: 10px;
  padding: 0px 30px;
  &::placeholder {
    color: #9f9f9f;
  }
}

.textarea {
  width: 100%;
  min-height: 120px;
  border: 1px solid #9f9f9f;
  border-radius: 10px;
  padding: 25px 30px;
  outline: none;
  &::placeholder {
    color: #9f9f9f;
  }
}

.shop-cart-bg {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  position: fixed;
}
.shop-cart-wrapper {
  position: fixed;
  max-width: 417px;
  width: 100%;
  right: 0;
  top: 0;
  z-index: 100;
  height: 100%;
  padding-bottom: 120px;
}
.shop-cart {
  background-color: $white;
  width: 100%;
  height: 100%;
  padding: 28px 35px 35px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.shop-cart-close {
  position: absolute;
  right: 35px;
  top: 30px;
  cursor: pointer;
}
.shop-cart-title {
  font-weight: 600;
  font-size: 24px;
  color: #000;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 25px;
  margin-bottom: 42px;
  max-width: 287px;
}
.shop-cart-top {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.shop-cart-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
}
.shop-cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-img {
    max-width: 105px;
    width: 100%;
    height: 105px;
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-box {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-right: auto;
    margin-left: 32px;
    &-info {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &-title {
        font-weight: 400;
      }
      &-price {
        font-weight: 500;
        font-size: 12px;
        color: #b88e2f;
        display: flex;
        align-items: center;
        gap: 15px;
        span {
          font-weight: 300;
          color: $black;
        }
        span:first-child {
          font-size: 16px;
        }
      }
    }
    &-icon {
      min-width: 20px;
    }
  }
}

.shop-cart-bottom {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 42px;
  padding-top: 15px;
}
.shop-cart-subtotal {
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  span {
    font-weight: 600;
    color: #b88e2f;
  }
}
.shop-cart-btns {
  display: flex;
  align-items: center;
  gap: 15px;
  a {
    border: 1px solid $black;
    border-radius: 50px;
    padding: 6px 30px;
    font-weight: 400;
    font-size: 12px;
    color: $black;
    transition: all 0.3s ease;
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}

.select {
  width: 100%;
  height: 75px;
  border: 1px solid #9f9f9f;
  border-radius: 10px;
  padding: 0px 30px;
  outline: none;
  font-weight: 400;
  color: #9f9f9f;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 6L10 11L15 6L17 7L10 14L3 7L5 6Z' fill='black'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 26px;
  &::placeholder {
    color: #9f9f9f;
  }
}
