@import 'vars';
@import 'reset';
@import 'global';
.header {
  background-color: $white;
}
.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 95px;

  .header__inner-logo {
    position: relative;
    z-index: 3;
    a {
      display: flex;
    }
  }
}
.header__inner-nav {
  display: flex;
  align-items: center;
  .nav-list {
    display: flex;
    align-items: center;
    > li {
      & + li {
        margin-left: 78px;
      }
      a {
        color: $black;
      }
      a:hover {
        color: $black;
      }
      a.active {
        font-weight: 500;
        color: $black;
      }
    }
    > a {
      margin-left: 48px;
    }
    .nav-list-item {
      position: relative;

      .nav-list-item-drop-down {
        visibility: hidden;
        z-index: 1;
        transform: translate(0px, 10%);
        opacity: 0;
        position: absolute;
        left: -15px;
        top: 40px;
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
        padding: 8px 0px;
        pointer-events: none;
        transition: all 0.3s ease;
        min-width: 150px;
        ul li {
          display: flex;
          a {
            padding: 5px 20px;
            white-space: nowrap;
            width: 100%;
          }
        }
      }
      &.drop-down.active {
        .nav-list-item-drop-down {
          visibility: visible;
          transform: translate(0px, 0px);
          opacity: 1;
          pointer-events: all;
        }
        .nav-list-item-btn svg {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
    .nav-list-item.drop-down .nav-list-item-btn {
      display: flex;
      align-items: center;
      position: relative;
      padding-right: 20px;
      cursor: pointer;
      svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 7px;
        transition: all 0.3s ease;
      }
    }
  }
}
.header__inner-link {
  display: flex;
  align-items: center;
  position: relative;
  a {
    display: flex;
  }
  a + a {
    margin-left: 35px;
  }
}
.cart-counter {
  position: absolute;
  top: -7px;
  right: -7px;
  width: 20px;
  height: 20px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 11px;
}

.header-banner {
  position: relative;
  .container {
    max-width: 1430px;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}
.header-banner__inner {
  padding-top: 170px;
  padding-bottom: 66px;
  display: flex;
  justify-content: flex-end;
}
.header-banner-card {
  background-color: $white;
  padding: 48px 48px 42px 40px;
  border-radius: 10px;
  max-width: 675px;
  width: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    font-weight: 700;
    color: #0f0f0f;
    max-width: 400px;
    margin-bottom: 30px;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 133%;
    color: #333;
    margin-bottom: 60px;
  }
  a {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    max-width: 222px;
    width: 100%;
    padding: 25px 0px;
    background-color: #0f0f0f;
    border: 1px solid transparent;
    transition: all 0.3s ease;
    text-align: center;
    &:hover {
      border: 1px solid #0f0f0f;
      background-color: transparent;
      color: #0f0f0f;
    }
  }
}
.header-banner-card-label {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.19em;
  color: #333;
  margin-bottom: 3px;
}

.collection {
  padding-bottom: 56px;
  padding-top: 97px;
  border-bottom: 1px solid #d9d9d9;
}
.collection__inner {
  display: flex;
  flex-direction: column;
  gap: 35px;
  h2 {
    font-weight: 700;
    text-align: center;
    color: #333;
  }
}
.collection-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 22px;
}
.collection-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 33px;
  align-items: center;
  &-img {
    width: 100%;
    height: 535px;
    border-radius: 15px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    color: #333;
  }
}

.sellers {
  padding-top: 45px;
  padding-bottom: 60px;
  border-bottom: 1px solid #d9d9d9;
}
.sellers__inner {
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 700;
    text-align: center;
    color: #333;
    margin-bottom: 45px;
  }
  > a {
    font-weight: 600;
    line-height: 150%;
    color: #575757;
    max-width: 245px;
    width: 100%;
    padding: 12px;
    text-align: center;
    margin: 0 auto;
    border: 1px solid #575757;
    transition: all 0.3s ease;
    &:hover {
      background-color: #575757;
      color: $white;
    }
  }
}
.sellers-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px 39px;
  margin-bottom: 45px;
}
.furni-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Rubik', sans-serif;
  &-info {
    padding: 12px 18px;
  }
  &-img {
    border-radius: 15px;
    overflow: hidden;
    height: 387px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-title {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: #505050;
  }
  &-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-text {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 144%;
      letter-spacing: -0.02em;
      color: #121212;
      flex-wrap: wrap;
      span {
        font-size: 16px;
        line-height: 150%;
        text-decoration: line-through;
        color: #9c9c9c;
        margin-right: 15px;
      }
    }
    &-icon {
      display: flex;
    }
  }
}

.best-furniture {
  padding: 70px 0px;
}
.best-furniture__inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
}

.best-furniture-text {
  max-width: 565px;
  width: 100%;
  &-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 130%;
    text-transform: capitalize;
    color: #23262f;
    margin-bottom: 45px;
  }
  &-description {
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    color: #23262f;
  }
}
.best-furniture-img {
  img {
  }
}

.discount {
  position: relative;
  img {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.discount__inner {
  display: flex;
  justify-content: flex-end;
  padding: 100px 0px 142px;
}
.discount-box {
  display: flex;
  flex-direction: column;
  color: $white;
  max-width: 558px;
  width: 100%;
  &-title {
    font-weight: 600;
    font-size: 40px;
    line-height: 135%;
    margin-bottom: 20px;
    max-width: 385px;
  }
  &-text {
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    margin-bottom: 40px;
  }
  &-form {
    display: flex;
    align-items: center;

    gap: 22px;
    input {
      width: 100%;
      background-color: $white;
      border-radius: 8px;
      height: 100%;
      padding-left: 35px;
      padding-right: 20px;
      color: $black;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      height: 60px;
      &::placeholder {
        color: #23262f;
        opacity: 0.5;
      }
    }
    button {
      font-family: 'Saira', sans-serif;
      max-width: 152px;
      width: 100%;
      height: 100%;
      height: 60px;
      background-color: #23262f;
      border-radius: 8px;
      color: $white;
      font-weight: 600;
      font-size: 20px;
      line-height: 100%;
      border: 1px solid transparent;
      transition: all 0.3s ease;
      &:hover {
        border-color: #23262f;
        background-color: $white;
        color: #23262f;
      }
    }
  }
}

.filter {
  background-color: #cecece;
}
.filter__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 105px;
}
.filter-left {
  display: flex;
  align-items: center;
}
.filter-btn {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-right: 25px;
}
.filter-list {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-right: 32px;
  &-item {
    display: flex;
    cursor: pointer;
  }
}
.filter-text {
  padding-left: 36px;
  position: relative;
  font-weight: 400;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 36px;
    background-color: #9f9f9f;
  }
}
.filter-right {
  display: flex;
  align-items: center;
  gap: 30px;
  white-space: nowrap;
  select {
    font-weight: 400;
    font-size: 20px;
    color: #9f9f9f;
    padding: 13px 15px 14px 15px;
    background-color: $white;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
  }
  > div {
    display: flex;
    align-items: center;
    gap: 18px;
    font-size: 20px;
  }
  .filter-show {
    select {
      width: 58px;
    }
  }
  .filter-short {
    select {
      width: 200px;
    }
  }
}

.shop-list {
  padding: 40px 0px 75px;
}
.shop-list__inner {
  display: flex;
  flex-direction: column;
  gap: 75px;
}
.shop-list-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px 39px;
}
.banner-breadcrumbs {
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
}
.banner-breadcrumbs__inner {
  padding-top: 127px;
  padding-bottom: 100px;
  flex-direction: column;
  display: flex;
  align-items: center;
}
.banner-breadcrumbs-title {
  font-size: 48px;
  color: #171717;
  line-height: 120%;
  margin-bottom: 17px;
}
.banner-breadcrumbs-list {
  display: flex;
  align-items: center;
  gap: 33px;
  a {
    color: #000;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: -22px;
      top: 50%;
      transform: translateY(-50%);
      width: 9px;
      height: 15px;
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 13.1749L5.25 7.9249L0 2.6749L1.05 0.574902L8.4 7.9249L1.05 15.2749L0 13.1749Z' fill='black'/%3E%3C/svg%3E%0A");
    }
  }
  span {
    font-weight: 300;
  }
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 38px;
  .pagination-item {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 24px;
    border-radius: 10px;
    background-color: #eaeaea;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  .pagination-item.selected {
    background-color: #575757;
    color: $white;
  }
  .pagination-item.disabled {
    display: none;
  }
}

.furniture-cart {
  padding-top: 35px;
  padding-bottom: 87px;
  border-bottom: 1px solid #d9d9d9;
}
.furniture-cart__inner {
  display: flex;
  gap: 30px;
}
.furniture-cart-imgslider-box {
  max-width: 637px;
  width: 100%;
}
.furniture-cart-imgslider {
  display: flex;
  gap: 32px;
  .mySwiper {
    max-width: 80px !important;
    width: 100%;
    .swiper-wrapper {
      flex-wrap: wrap;
      align-content: flex-start;
      gap: 33px;
      .swiper-slide {
        height: 85px;
        max-width: 80px !important;
        width: 100% !important;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .mySwiper2 {
    max-width: 525px;
    width: 100%;
    height: 525px;
    object-fit: cover;
    .swiper-slide {
      width: 100% !important;
      border-radius: 10px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.furniture-cart-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  &-title {
    font-weight: 400;
    font-size: 42px;
    line-height: 130%;
  }
  &-price {
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
    color: #9f9f9f;
    margin-bottom: 16px;
  }
  &-stars {
    display: flex;
    align-items: center;
    gap: 42px;
    margin-bottom: 19px;
    span {
      font-weight: 400;
      font-size: 13px;
      color: #9f9f9f;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 31px;
        background-color: #9f9f9f;
        left: -25px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &-description {
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 32px;
    max-width: 445px;
  }
  &-color {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-weight: 400;
    font-size: 14px;
    color: #9f9f9f;
    margin-bottom: 33px;
    &-items {
      display: flex;
      align-items: center;
      gap: 17px;
    }
    &-item {
      border-radius: 50px;
      width: 31px;
      height: 31px;
      background-color: #bc9154;
      &.blue {
        background-color: #25384d;
      }
      &.gray {
        background-color: #575551;
      }
    }
  }
  &-con {
    display: flex;
    gap: 19px;
    width: 100%;
    padding-bottom: 63px;
    margin-bottom: 42px;
    border-bottom: 1px solid #d9d9d9;
  }
  &-counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 80px;
    width: 100%;
    height: 30px;
    border: 1px solid #9f9f9f;
    border-radius: 10px;
    padding: 0px 5px;
    button {
      font-weight: 400;
      height: 100%;
      width: 20px;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    &-item {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      color: #9f9f9f;
      gap: 20px;
      &-title {
        max-width: 78px;
        width: 100%;
      }
      &-text {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }
}

.description {
  padding-top: 53px;
  padding-bottom: 82px;
  border-bottom: 1px solid #d9d9d9;
  .description-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #000;
    margin-bottom: 47px;
    text-align: center;
  }
  p {
    font-weight: 400;
    text-align: justify;
    color: #9f9f9f;
    margin-bottom: 100px;
    line-height: 140%;
  }
  .description-title-img {
    display: flex;
    gap: 30px;
    width: 100%;
    &-item {
      width: 100%;
      height: 440px;
      border-radius: 10px;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.contact {
  padding-top: 100px;
  padding-bottom: 87px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.17);
}
.contact__inner {
  display: flex;
  flex-direction: column;
}
.contact-title {
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 7px;
}
.contact-text {
  font-weight: 400;
  text-align: center;
  color: #9f9f9f;
  max-width: 646px;
  margin: 0 auto;
  margin-bottom: 133px;
}
.contact-row {
  display: flex;
  justify-content: space-between;
  max-width: 962px;
  width: 100%;
  margin: 0 auto;
  gap: 20px;
}
.contact-info {
  display: flex;
  flex-direction: column;
  gap: 42px;
  min-width: 265px;
}
.contact-info-item {
  display: flex;
  gap: 30px;
  &-icon {
    svg {
      max-width: unset;
    }
  }
  &-box {
    max-width: 212px;
    width: 100%;

    &-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 140%;
      color: #000;
    }
    &-text {
      font-weight: 400;
      line-height: 140%;
      a {
        color: $black;
      }
    }
  }
}
.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 530px;
  width: 100%;
  button {
    font-weight: 400;
    max-width: 237px;
    height: 55px;
    border-radius: 5px;
    background-color: #575757;
    color: $white;
    margin-top: 50px;
  }
}
.contact-form-item {
  display: flex;
  flex-direction: column;
  gap: 22px;

  & + & {
    margin-top: 35px;
  }
}

.checkout {
  padding-bottom: 120px;
  padding-top: 63px;
  .contact-form {
    gap: 35px;
  }
}
.checkout-form {
  display: flex;
  gap: 25px;
  .contact-form-item + .contact-form-item {
    margin-top: 0px;
  }
  .contact-form-item-con {
    display: flex;
    gap: 30px;
    .contact-form-item {
      width: 100%;
    }
  }
}
.checkout-form-left {
  width: 100%;
  padding: 35px 80px 70px 75px;
  display: flex;
  flex-direction: column;
}
.checkout-form-right {
  width: 100%;
  padding: 87px 38px 160px;
  display: flex;
  flex-direction: column;
  .btn {
    max-width: 318px;
    margin: 0 auto;
  }
}

.checkout-form-title {
  font-weight: 600;
  font-size: 36px;
  margin-bottom: 35px;
  line-height: 120%;
}
.checkout-form-product,
.checkout-form-upholstered,
.checkout-form-subtotal,
.checkout-form-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkout-form-product {
  margin-bottom: 15px;
  span {
    font-size: 24px;
    line-height: 120%;
  }
}
.checkout-form-upholstered {
  margin-bottom: 22px;
  gap: 15px;
  .checkout-form-upholstered-text {
    font-weight: 500;
    font-size: 12px;
    color: #000;
    display: flex;
    align-items: center;
    gap: 10px;
    span:first-child {
      font-weight: 400;
      font-size: 16px;
      color: #9f9f9f;
    }
  }
  > span {
    font-weight: 300;
  }
}
.checkout-form-subtotal {
  margin-bottom: 22px;
  span {
    font-weight: 400;
  }
  span:last-child {
    font-weight: 300;
  }
}
.checkout-form-total {
  padding-bottom: 40px;
  margin-bottom: 22px;
  border-bottom: 1px solid #d9d9d9;
}
.checkout-form-description {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  p {
    font-weight: 300;
    text-align: justify;
    color: #000;
  }
  span {
    margin-bottom: 10px;
    font-weight: 400;
    position: relative;
    padding-left: 30px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      background-color: $black;
      border-radius: 50%;
    }
  }
  span + p {
    color: #9f9f9f;
  }
  p + p {
    margin-top: 32px;
    a {
      font-weight: 600;
      color: $black;
    }
  }
}

.cart {
  padding: 75px 0px 87px;
}
.cart__inner {
  display: flex;
  gap: 30px;
}
.cart-table {
  width: 100%;
}
.cart-panel {
  max-width: 412px;
  width: 100%;
  background-color: #f6f6f6;
  padding: 15px;
  padding-bottom: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cart-panel-title {
  margin-bottom: 65px;
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
}
.cart-panel-subtotal {
  display: flex;
  align-items: center;
  gap: 65px;
  margin-bottom: 32px;
  span {
    font-weight: 400;
  }
  span:first-child {
    font-weight: 500;
  }
}
.cart-panel-total {
  display: flex;
  align-items: center;
  gap: 85px;
  margin-bottom: 50px;
  span {
    font-weight: 500;
  }
  span:last-child {
    font-weight: 500;
    font-size: 20px;
    color: #575757;
  }
}

.cart-table {
  border-collapse: collapse;
  height: fit-content;
  button {
    svg {
      min-width: 30px;
    }
  }
  thead tr th {
    background-color: #cecece;
    font-weight: 500;
    padding: 15px 10px;
    text-align: left;
  }
  thead tr th:first-child {
    text-align: center;
  }
  tbody tr:first-child td {
    padding: 57px 10px 30px;
  }
  tbody tr td {
    padding: 30px 10px 30px;
    font-weight: 400;
    color: #9f9f9f;
  }
  tbody tr td:first-child {
    padding-left: 0px;
  }
}

.cart-table-product {
  display: flex;
  align-items: center;
  gap: 35px;
  &-img {
    min-width: 105px;
    height: 105px;
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.cart-table-number {
  width: 33px;
  height: 33px;
  border: 1px solid #9f9f9f;
  border-radius: 5px;
  font-weight: 400;
  -moz-appearance: textfield;
  text-align: center;
  color: $black;
}
.cart-table-number::-webkit-outer-spin-button,
.cart-table-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cart-table-price {
  color: $black;
}
.cart-table-quantity {
  text-align: center !important;
}
.cart-panel-subtotal,
.cart-panel-total {
  max-width: 225px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 32px;
}
.cart-table-box {
  width: 100%;
}

.footer {
  background-color: $white;
  color: $black;
  padding-top: 50px;
  padding-bottom: 100px;
  .container {
    max-width: 1320px;
  }
  .footer__inner {
    display: flex;
    flex-direction: column;

    .footer__inner-items {
      width: 100%;
      display: flex;
      align-items: flex-start;

      padding-bottom: 50px;
      border-bottom: 1px solid #d9d9d9;

      .footer__inner-item:first-child {
        max-width: 300px;
        width: 100%;
        margin-right: 143px;
        .footer__inner-item-title {
          opacity: 0;
        }
        ul li a {
          font-weight: 400;
          color: #9f9f9f;
          line-height: 140%;
        }
      }
      .footer__inner-item:nth-child(2) {
        margin-right: 155px;
      }
      .footer__inner-item:last-child {
        max-width: 300px;
        width: 100%;
        margin-left: 83px;
      }
      .footer__inner-item {
        display: flex;
        flex-direction: column;
        .footer__inner-item-title {
          color: #9f9f9f;
          margin-bottom: 60px;
        }
        ul li {
          display: flex;
          align-items: flex-start;
          img {
            margin-right: 25px;
          }
          & + li {
            margin-top: 50px;
          }
          a {
            color: $black;
          }
        }
        .footer__inner-item-card {
          padding: 15px 15px 20px 15px;
          background-color: $black;
          border-radius: 10px;

          p {
            margin-top: 20px;
            font-size: 13px;
            font-weight: 500;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

@import 'media';
