@media (max-width: 1200px) {
  .footer .footer__inner .footer__inner-items .footer__inner-item:first-child {
    max-width: 230px;
  }
  .footer .footer__inner .footer__inner-items .footer__inner-item:first-child {
    margin-right: 0px;
  }
  .footer .footer__inner .footer__inner-items .footer__inner-item:nth-child(2) {
    margin-right: 0px;
  }
  .footer .footer__inner .footer__inner-items .footer__inner-item:last-child {
    margin-left: 0px;
  }
  .footer .footer__inner .footer__inner-items {
    gap: 60px;
  }
}
@media (max-width: 1024px) {
  .footer .footer__inner .footer__inner-items {
    gap: 30px;
  }

  .header__inner-nav .nav-list > li + li {
    margin-left: 40px;
  }
  .header-banner__inner {
    padding: 50px 0px;
  }
  h1 {
    font-size: 40px;
  }
  .collection-item-img {
    height: 400px;
  }
  .collection {
    padding: 35px 0px;
  }
  .sellers-items {
    gap: 20px;
  }
  .best-furniture-text {
    max-width: 400px;
  }
  .discount__inner {
    padding: 50px 0px;
  }
  .banner-breadcrumbs__inner {
    padding: 50px 0px;
  }

  .checkout-form-left {
    padding: 25px;
  }
  .checkout-form-right {
    padding: 25px;
  }
  .checkout-form .contact-form-item-con {
    flex-direction: column;
  }
  .filter__inner {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }
  .filter-right select {
    padding: 0px 5px;
  }
  .filter-right .filter-show select {
    width: auto;
  }
  .filter-right > div {
    font-size: 16px;
  }
  .shop-list-items {
    gap: 20px;
  }
  .furniture-cart-imgslider {
    flex-direction: column;
  }
  .furniture-cart-imgslider .mySwiper {
    order: 1;
    max-width: 100% !important;
  }
  .furniture-cart-imgslider .mySwiper .swiper-wrapper {
    flex-wrap: nowrap;
  }
  .furniture-cart-imgslider .mySwiper .swiper-wrapper .swiper-slide {
    max-width: unset !important;
    width: unset !important;
  }
  .furniture-cart-imgslider .mySwiper .swiper-wrapper {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .furniture-cart-imgslider .mySwiper2 {
    height: 400px;
  }
}
@media (max-width: 900px) {
  .cart__inner {
    flex-direction: column;
  }
  .cart-panel {
    max-width: 100%;
  }
  .cart-table-box {
    overflow: auto;
  }
}

@media (max-width: 768px) {
  .cart {
    padding: 50px 0px;
  }
  body {
    font-size: 14px;
  }
  .header__inner {
    min-height: 80px;
  }
  .header__inner .header__inner-logo a {
    width: 90px;
  }
  .header__menu-btn {
    display: block;
    z-index: 2;
  }
  .header__inner.active {
    .header__menu-btn span {
      background: $white;
    }
    .header__inner-logo a svg path {
      fill: $white;
    }
    .header__inner-link {
      a svg path:first-child {
        fill: $white;
      }
      a svg path:last-child {
        stroke: $white;
      }
      a + a {
        margin-left: 35px;
      }
    }
  }
  .header__inner-nav .global-btn {
    display: block;
    width: 100%;
  }
  .header__inner-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: $black;
    left: 0;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 0%;
    padding: 60px 20px 30px;
    overflow-x: hidden;
    overflow-y: hidden;
    opacity: 0;
    visibility: hidden;
    transform: translate(0px, -100%);
  }
  .header__inner-nav.active {
    transform: translate(0px, 0px);
    transition: all 0.3s ease;
    opacity: 1;
    visibility: visible;
    height: 100%;
    z-index: 2;

    .nav-list-item.drop-down {
      .nav-list-item-btn {
        color: $white;
        svg path {
          fill: $white;
        }
      }
    }
    .nav-list-item.drop-down.active {
      .nav-list-item-drop-down {
        position: static;
        background-color: transparent;
        display: flex;
        ul li a {
          text-align: left;
        }
      }
    }
    .nav-list {
      flex-direction: column;
      width: 100%;
      margin: auto 0px;
      align-items: flex-start;
      overflow-y: auto;
      > a {
        margin-left: 0px;
        margin-top: 15px;
      }
      .nav-list-item {
        a {
          width: 100%;
          color: $white;
          display: block;
          text-align: center;
        }
      }
      .nav-list-item + .nav-list-item {
        margin-left: 0px;
        margin-top: 15px;
      }
    }

    .nav-list .nav-list-item .nav-list-item-drop-down ul li a {
      white-space: normal;
    }

    .nav-list-item-drop-down {
      max-width: 100%;
      transition: none !important;
      display: none;
    }
  }
  .footer {
    padding: 50px 0px;
  }
  .footer
    .footer__inner
    .footer__inner-items
    .footer__inner-item
    .footer__inner-item-title {
    margin-bottom: 25px;
  }
  .footer .footer__inner .footer__inner-items {
    padding-bottom: 25px;
    flex-wrap: wrap;
  }
  .footer .footer__inner .footer__inner-items .footer__inner-item:first-child {
    max-width: 100%;
  }
  .footer
    .footer__inner
    .footer__inner-items
    .footer__inner-item:first-child
    .footer__inner-item-title {
    display: none;
  }
  .header__inner-link {
    margin-left: auto;
    margin-right: 20px;
  }
  .header__menu-btn {
    margin-left: unset;
  }
  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 28px;
  }
  .header-banner-card p {
    margin-bottom: 30px;
    font-size: 16px;
  }
  .header-banner-card {
    padding: 30px;
  }
  .collection-item-img {
    height: 300px;
  }
  .collection-item-text {
    font-size: 18px;
  }
  .collection-item {
    gap: 15px;
  }
  .furni-card-img {
    height: 250px;
  }
  .best-furniture-text-title {
    font-size: 24px;
    margin-bottom: 25px;
  }
  .best-furniture-text-description {
    font-size: 16px;
  }
  .best-furniture {
    padding: 50px 0px;
  }
  .discount-box-title {
    font-size: 30px;
  }
  .cart-panel-title {
    margin-bottom: 30px;
    font-size: 28px;
  }
  .cart-panel {
    padding: 20px;
  }
  .btn {
    min-height: 40px;
  }
  .banner-breadcrumbs__inner {
    padding: 30px 0px;
  }
  .banner-breadcrumbs-title {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .checkout-form-left {
    padding: 0px;
  }
  .checkout-form-right {
    padding: 0px;
  }
  .input {
    height: 50px;
  }
  .select {
    height: 50px;
    background-position-y: 15px;
  }
  .checkout-form-title {
    margin-bottom: 20px;
    font-size: 25px;
  }
  .checkout .contact-form {
    gap: 15px;
  }
  .checkout-form .contact-form-item-con .contact-form-item {
    gap: 15px;
  }
  .checkout-form .contact-form-item-con {
    gap: 15px;
  }
  .checkout {
    padding: 50px 0px;
  }
  .contact {
    padding: 50px 0px;
  }
  .contact-text {
    margin-bottom: 40px;
  }
  .pagination-container .pagination-item {
    height: 40px;
  }
  .pagination-container {
    gap: 15px;
  }
  .furniture-cart__inner {
    flex-direction: column;
  }
  .furniture-cart-imgslider-box {
    max-width: 100%;
  }
  .furniture-cart-imgslider .mySwiper2 {
    max-width: 100%;
  }
  .furniture-cart-info-counter {
    height: auto;
  }
  .furniture-cart {
    padding-bottom: 50px;
  }
  .description {
    padding: 50px 0px;
  }
  .description .description-title {
    margin-bottom: 30px;
  }
  .description p {
    margin-bottom: 40px;
  }
  .description .description-title-img-item {
    height: 200px;
  }
}
@media (max-width: 600px) {
  .header-banner-card h1 {
    margin-bottom: 20px;
  }
  h1 {
    font-size: 24px;
    line-height: 35px;
  }

  .checkbox-item {
    padding-left: 18px;
  }
  .checkbox-item label::after {
    left: -18px;
  }
  .checkbox-item label::before {
    left: -18px;
  }

  .footer {
    padding-top: 20px;
    padding-bottom: 35px;
  }
  .footer .footer__inner .footer__inner-logo {
    margin-bottom: 20px;
  }
  .footer .footer__inner .footer__inner-items {
    padding-left: 0px;
    padding-top: 20px;
  }
  .footer .footer__inner .footer__inner-items .footer__inner-item ul li {
    flex-direction: column;
    a {
      font-size: 12px;
    }
  }
  .footer .footer__inner .footer__inner-items .footer__inner-item ul li + li {
    margin-top: 20px;
  }
  .footer .footer__inner .footer__inner-items .footer__inner-item ul li img {
    margin-right: 0px;
    margin-bottom: 15px;
  }
  .footer
    .footer__inner
    .footer__inner-items
    .footer__inner-item
    .footer__inner-item-title {
    font-size: 16px;
  }
  h2 {
    font-size: 20px;
  }
  .collection-items {
    grid-template-columns: repeat(3, 200px);
    overflow-x: auto;
    overflow-y: hidden;
  }
  .collection-item-img {
    height: 250px;
  }
  .sellers-items {
    grid-template-columns: repeat(2, 1fr);
  }
  .best-furniture__inner {
    flex-direction: column;
  }
  .checkout-form {
    flex-direction: column;
  }
  .contact-form {
    max-width: 100%;
  }
  .contact-row {
    flex-direction: column;
    gap: 40px;
  }
  .contact-info {
    order: 1;
    gap: 30px;
  }
  .shop-list-items {
    grid-template-columns: repeat(2, 1fr);
  }
  .filter-right .filter-short select {
    width: 150px;
  }
  .filter-left {
    flex-wrap: wrap;
    gap: 10px;
  }
  .filter-text {
    padding-left: 0px;
  }
  .filter-text::before {
    display: none;
  }
  .filter {
    padding: 10px 0px;
  }
  .filter-text {
    width: 100%;
  }
  .furniture-cart-info-title {
    font-size: 30px;
  }
}
@media (max-width: 500px) {
  .description .description-title-img {
    flex-direction: column;
  }
  .furni-card-price {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .furni-card-title {
    font-size: 18px;
  }
  .discount-box-title {
    font-size: 25px;
  }
  .discount-box-form {
    flex-direction: column;
  }
  .discount-box-form button {
    max-width: 100%;
  }
  .shop-cart {
    padding: 20px;
  }
  .shop-cart-item-box {
    margin-left: 15px;
  }
  .shop-cart-item-img {
    max-width: 80px;
    height: 80px;
  }
  .furniture-cart-imgslider .mySwiper .swiper-wrapper {
    gap: 10px;
  }
  .furniture-cart-imgslider .mySwiper2 {
    height: 300px;
  }
  .furniture-cart-imgslider {
    gap: 20px;
  }
}
@media (max-width: 425px) {
  .furniture-cart-info-con {
    .btn {
      min-width: unset;
      max-width: 100%;
      width: 100%;
      white-space: nowrap;
    }
  }

  .furni-card-img {
    height: 200px;
  }
  .furni-card-info {
    padding: 12px 0px;
  }
  .furni-card-price-text {
    flex-direction: column;
    gap: 5px;
  }
  .filter-right .filter-short select {
    width: auto;
  }
  .filter-right {
    gap: 10px;
  }
  .filter-right > div {
    gap: 10px;
  }
}
